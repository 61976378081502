import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgProfileCeriTaylor from "../images/profile-ceri-taylor.jpg"
import imgClient from "../images/applegates-coaches-cover-photo.jpg"
import imgCaseStudy from "../images/applegates-coaches-case-study.jpg"
import "./index.css";
import "./case-study.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Case study: How Applegates guaranteed income and safety during COVID-19 by going cashless" />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <div className="case-study">

                <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                  <h1 className="blog-headline">How Applegates guaranteed income and safety by going cashless</h1>
                </div>

                <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                  <h1 className="blog-headline">How Applegates guaranteed income and safety by going cashless</h1>
                </div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>As Applegates Coaches grew their home-to-school services over a number of years, so did the complexity of handling cash fares. Then a global pandemic turned everything upside down.</p>
                    <p>Not only did physical cash and tickets suddenly turn into a safety concern for their passengers and staff, but the threat of volatile passenger numbers each day risked jobs and the viability of vital school services.</p>
                    <p>Using ShuttleID, Applegates now have a guaranteed income, with parents instead paying monthly by Direct Debit for a digital ticket displayed on the passenger's phone. A cost-effective scanner for ticket validation doubles up as a track and trace facility, giving parents and schools peace of mind.</p>
                  </div>
                  <div className='article-inner'>
                    <div className='case-study__metrics'>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>608,000</span>
                        fares per annum made safer
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>400 hours</span>
                        admin saved per year
                      </div>
                    </div>
                    <div className='case-study__about'>
                      <h3>About Applegates Coaches</h3>
                      <p><a href="http://www.applegates.co.uk/">Applegates Coaches</a> are a family run business based in rural Gloucestershire. They cater to a wide range of work, including local home-to-school routes with a double decker, through to continental holidays trips. The business has gradually grown from four school runs to eighteen, in addition to commercial work.</p>
                    </div>

                    <h2>Operating at a loss</h2>
                    <p>Applegates Coaches always dealt with physical cash on their home-to-school runs and over the years they've tried to evolve their system to reduce fare dodging and even occasional theft.</p>
                    <p>What started as something that was supposed to be simple, with little to no technology involved, quickly turned into an administration nightmare that ate up a couple of hours of someone's time every day.</p>
                    <p>Parents also loathed the system, as it created the stress of having to find change every single week, with some parents having to go to banks to draw out change just for the bus. Parents who paid for week tickets had the additional worry of trusting their child not to lose their ticket.</p>
                    <p><img src={imgCaseStudy} alt="Applegates Coaches" /></p>
                    <p>It was clear that this system was becoming increasingly unfit for purpose, but ultimately it was a global pandemic that forced the hand of the operator to find a simpler, safer, cashless system.</p>
                    <p>With Covid-19, Applegates found the national lockdown challenging as vehicles didn't leave the yard, staff were on furlough and overheads were still being paid. The looming crisis however was returning in September, when volatile passenger numbers could mean services would be operated at a loss, throwing the viability of the school services into question.</p>

                    <h2>Scared off by the big ticket machine companies</h2>
                    <p>Ceri Taylor, managing director of Applegates, knew that going cashless was inevitable, but his experience with the big ticket machine companies did not leave him with confidence.</p>
                    <p>The immediate concerns were that they were too expensive to set up and maintain but they were also too complicated for their drivers to easily grasp. Applegates needed a way to go cashless that didn't cost the earth and was simple enough to use by their drivers, without increasing fares and losing even more customers.</p>
                    <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileCeriTaylor} alt="Ceri Taylor" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p>"We looked into systems with the big ticket machine companies and for what we were doing, AM and PM with a double decker, the cost of it was just phenomenal."</p>
                        <p>"I'd been scared off by the big ticketing companies before and I thought everyone's going to be around the same sort of prices."</p>
                        <p className='case-study__profile-quote__author'>Ceri Taylor, Managing Director, Applegates Coaches</p>
                      </div>
                    </div>
                    <p>When Ceri received an email that described ShuttleID, a cost-effective cashless bus pass and ticketing system built specifically for home-to-school transport, he immediately saw the potential.</p>
                    <p>As the system uses consumer grade tablet hardware to scan tickets, Applegates could afford to buy and install the devices needed for a fraction of the price he had originally come to expect.</p>
                    <div className='case-study__quote'>
                      <p>"It's not a big posh ticket machine, singing and dancing that spits paper out the side, we don't need it and that's not what we wanted. If you can keep it simple, then that's all the better for us."</p>
                      <p className='case-study__quote-author'>&mdash; Ceri Taylor, Managing Director, Applegates Coaches</p>
                    </div>
                    <p>Working closely with ShuttleID, Applegates started selling their tickets online for the first time ever over the summer holidays, ahead of September. But with one key difference; the majority of these tickets were now annual and with payments automatically made each month through Direct Debit.</p>
                    <p>This strategy meant that even with fluctuating passenger numbers, there is guaranteed income, safeguarding the school services and jobs for the staff that operate them.</p>
                    <p>What's more, the system addressed key safety concerns:</p>
                    <ul>
                      <li>No more physical cash to handle which improving safety for passengers and drivers</li>
                      <li>No more physical tickets to hand out as tickets are now sent digitally and presented on phones or printed-at-home</li>
                      <li>A scanner device performs the job of checking passes which further reduces interaction between passenger and driver</li>
                      <li>A track and trace system enabling operators to know which passengers boarded, when and who with</li>
                      <li>Targeted SMS service alerts for critical messaging when they need to contact parents directly</li>
                    </ul>

                    <h2>"It's saved our bacon"</h2>
                    <p><img src={imgClient} alt="Applegates Coaches" /></p>
                    <p>The introduction of the system has provided some well needed security to Applegates in uncertain times and overall it has gone down well with both employees and customers.</p>
                    <div className='case-study__quote'>
                      <p>"It's a system that doesn't cost you a lot of money, but it ticks lots of safety boxes. And it also guarantees you your revenue."</p>
                      <p className='case-study__quote-author'>&mdash; Ceri Taylor, Managing Director, Applegates Coaches</p>
                    </div>
                    <p>Drivers think it's brilliant as it helps make their job safer and the simple interface ensures they all understand it, including part time staff who use it only a couple of times a month. The office staff no longer have the admin headache of counting cash and handling physical tickets, as everything is digital.</p>
                    <p>Parents don't need to worry about finding the correct change every week and they have peace of mind their children don't need to handle physical cash or tickets that could be contaminated with the virus. Being able to purchase their ticket at any time of day or night and to receive it instantly is a big convenience too. With the ticket being digital, they are reassured it cannot be lost by their children and can be accessed again easily without charge.</p>
                    <p>The data of ticket purchases and scans has given Applegates new insight which is already paying dividends. From a safety perspective, understanding exactly who boarded and when gives them a track and trace facility. Real time visibility of how many tickets they're selling also means they can react to change, putting on exactly the right size vehicle and even restricting or allowing more ticket sales to maximise their services commercially.</p>
                    <p>Ceri pulls no punches in describing the importance of creating financial stability during the pandemic: <strong>"I knew that September onwards is going to be when the shit hits the fan. Operators have had their CBILS, the loans are backing up and they're running their buses. But come January (2021) onwards, this virus is still here, these operators are going to be running out of money. And they need to get onto a system like ShuttleID where they've got guaranteed income. It's saved our bacon really."</strong></p>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
